import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Metropolis',
  },
  palette: {
    text: {
      primary: '#4c4c4c'
    },
    background: {
      default: '#f8f8f8'
    },
  }
});

export default theme;