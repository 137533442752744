import React from 'react';
import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout";


const TopLayout = (props) => {
  return (
    <ThemeTopLayout theme={props.theme}>
      {props.children}
    </ThemeTopLayout>
  )
}


export default TopLayout